<template>
  <div class="detail_card">
    <!-- 一 职位专员电话-->
    <div class="article-head">
      <div style="width:15%;">
        <img style="width:35px;height:35px;" src="../assets/image/icon@2x.png">
      </div>
      <div style="width:85%;display:flex;" @click="toContentDetail(item.product_id)">
        <div style="width: 90%; color: #000000;font-weight: 600;font-size: 18px;">
          <p style="width: 90%;">{{item.name}}</p>
        </div>
        <div style="width: 10%; color: #2F4891; margin-top: 5px;">
          <van-icon name="arrow"/>
        </div>
      </div>
    </div>
    <div class="article-li">
      <div>
        <span class="li-title">客户</span>
        <span class="li-value">{{item.customer_id_info.name || ''}}</span>
      </div>
      <div>
        <span class="li-title">联系人</span>
        <span class="li-value">{{item.contacts_id_info.name || ''}}</span>
      </div>
      <div>
        <span class="li-title">销售</span>
        <span class="li-value">{{item.business_create_user.realname || ''}}</span>
      </div>
      <div>
        <span class="li-title">报价人</span>
        <span class="li-value">{{item.create_user_id_info.realname || ''}}</span>
      </div>
      <div>
        <span class="li-title">报价时间</span>
        <span class="li-value">{{ item.update_date }}</span>
      </div>
    </div>
    <!-- 一end-->
    <div style="display: flex;">
      <div style="width:70%;">
        <div><span class="other-by">总价（CNY）</span><span class="other-value">{{item.price}}</span></div>
        <div><span class="other-by">最终优惠价</span><span class="other-value">{{item.discount}}</span></div>
      </div>
      <div style="width:30%;">
          <van-button style="width:96px;height:38px;border-radius: 23px;border: 1px solid rgba(69,85,140,0.54);color:#2F4891" plain round @click="toContentDetail(item.product_id)" type="primary">审批</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Button, Icon } from "vant";
import { showDate } from "./../utils/tool";
export default {
  name: "detailCard",
  props: {
    item: {
      default: {}
    }
  },
  components: {
    Button,
    Icon
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    // console.log("item是", this.item);
  },
  computed: {},
  methods: {
    showDate,
    //访问JD
    toContentDetail: function(id) {
      this.$router.push("/product/detail?id=" + id);
    }
  }
};
</script>

<style lang="less">
.detail_card {
  width: 351px;
  height: 269px;
  background: #ffffff;
  border-radius: 15px;
  padding: 17px;

  .article-head {
    width: 100%;
    font-size: 17px;
    display: flex;
  }
  .article-li {
    height: 115px;
    margin: 16px 6px;
    div {
      width: 100%;
      margin: 2px 0;
      display: flex;
    }
    .li-title {
      width: 80px;
      font-size: 15px;
      color: #999999;
      font-weight: 400;
    }
    .li-value {
      color: #4e4e4e;
      font-size: 15px;
      
    }
  }

  .other-by{
    display: block;
    width: 85px;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    float: left;
  }
  .other-value{
      color: #2F4891;
      font-weight: 600;
      font-size: 18px;
    float: left;
  }
}
</style>
