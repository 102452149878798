<template>
  <div class="page" :style="bgSty">
    <!--用户信息-->
    <div class="content_header">
      <!-- 一 检索窗口-->
      <van-search
        v-model="search"
        show-action
        label
        shape="round"
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <!-- 一 数量-->
      <van-tabs v-model="active" title-active-color="#333333" title-inactive-color="#5E698B">
        <van-tab :title="'待审批（'+num1+')'"/>
        <van-tab :title="'已审批（'+num2+')'"/>
      </van-tabs>
    </div>
    <!--用户导航-->
    <div class="content">
      <Loading :show="show"/>
        <!-- <van-loading type="spinner" color="#1989fa" v-if="show"/> -->
        <div v-if="articleList.length">
          <div :key="i" class="article-list" v-for="(item, i) in articleList">
            <DetailCard :item="item"/>
          </div>
          <van-pagination
            v-model="page"
            :total-items="listCount"
            :items-per-page="3"
            mode="simple"
            @change="gotoChange"
          />
        </div>
        <div v-else>
          <van-empty description="暂无数据"/>
        </div>
    </div>
  </div>
</template>

<script>
import Top from "./../../components/top";
import { crmProductIndex, crmProductNum, getUserInfo } from "./../../api/user";
import {
  Toast,
  Dialog,
  Button,
  Icon,
  Pagination,
  Tab,
  Tabs,
  Search,
  // Loading
} from "vant";
import DetailCard from "../../components/detail_card";
import Loading from "../../components/loading";
import Store from "store";
import { mapState } from "vuex";

export default {
  name: "product",
  components: {
    Top,
    Button,
    Icon,
    Pagination,
    DetailCard,
    Tab,
    Tabs,
    Search,
    Loading
  },
  data() {
    return {
      bgSty: "",
      isIPhoneX: false,
      userInfo: {},
      search: "",
      num1: 0,
      num2: 0,
      active: 0, //标签
      show: false,
      articleList: [],
      pagesize: 3,
      page: 1, //页数
      listCount: 0, //总数
      nodataStatus: false, //加载中没有更多数据
      finished: false
    };
  },
  created() {
    this.isIPhoneX = Store.get("isIPhoneX") || false;
    // console.log("手机尺寸大小是", screen);
    this.bgSty = "width:100%;bottom:100px;height:" + (screen.height + 150) + "px;";
    //this.getIsIPhoneX(); //是否iphoneX赋值.
    this.userInfo = Store.get("userInfo");
    // console.log("this.userInfo   ", this.userInfo);
    this.getNeedMessagNum();
    this.getIndexList();
  },
  computed: {},
  methods: {
    gotoChange(current) {
      this.page = current;
      this.getIndexList();
    },
    // onLower() {
    //   console.log("加载");
    //   if (this.page <= this.pageCount) {
    //     this.page++;
    //     this.getIndexList();
    //   }
    // },
    onSearch() {
      console.log("检索");
      this.articleList = []
      this.show = false
      this.page = 1
      this.listCount = 0
      this.getNeedMessagNum();
      this.getIndexList();
    },
    /*
     **招聘列表数据接口
     **我的项目列表
     */

    async getIndexList() {
      this.show = true;
      var params = {
        page: this.page,
        limit: this.pagesize,
        search: this.search
      };
      if (this.active == 0) {
        params.check_user_id = this.userInfo.id;
        params.check_status = {
          'condition' : 'contains',
          'value': 1,
          'form_type': 'select',
          'name': '审批状态'
        }
      } else if (this.active == 1) {
        params.flow_user_id = this.userInfo.id;
        params.check_status = {
          'condition' : 'is',
          'value': ['待审核' , '审核通过' , '驳回修改'],
          'form_type': 'select',
          'name': '审批状态'
        }
      }
      let res = await crmProductIndex(params);
      if (res.code == 200) {
        this.listCount = res.data.dataCount;
        this.articleList = res.data.list;
        this.show = false;
      } else {
        this.show = false;
        console.log("系统api获取职位getIndexList错误", res);
      }
    },
    //
    async getNeedMessagNum(id = 0) {
      var params = {
        search: this.search
      };
      let res = await crmProductNum(params);
      if (res.code == 200) {
        this.num1 = res.data[1];
        this.num2 = res.data[2];
      }
    }
  },
  watch: {
    active(val) {
      this.onSearch()
    }
  }
};
</script>

<style lang="less">
.page {
  background-color: #F7F7F7;
  overflow-y: auto;
  /*用户信息*/
  .content_header {
    background: linear-gradient(180deg, #d2dff0 0%, #f7f7f7 100%);
    width: 100%;
    height: 300px;
    color: #fff;
    padding: 12px;
  }

  .van-search {
    background-color: #fff;
    border-radius: 30px;
    height: 44px;
    margin-bottom: 18px;
  }
  .van-search__content {
    background-color: #fff !important;
  }
  .van-search__action {
    margin-right: 12px;
    font-size: 15px;
    font-weight: 400;
    color: #2f4891;
  }

  .van-cell {
    color: #999999 !important;
  }

  .van-tabs__nav {
    background: none !important;
    width: 200px;
  }

  .van-tab {
    display: block;
    width: 100%;
    padding: 0 5px;
    font-size: 15px;
  }

  .van-tabs__line {
    width: 34px;
    height: 4px;
    background: #2f4891;
  }

  .van-tabs--line .van-tabs__wrap {
    height: 40px !important;
  }

  .un-name2 {
    font-size: 24px;
    font-weight: 500;
  }

  .h-view2 {
    font-size: 12px;
    color: #1c2e3a;
    line-height: 20px;
  }

  .content {
    top: 120px;
    position: absolute;
    padding: 12px;
    width: 100%;

    .article-list{
      margin-bottom: 10px;
    }
  }
  
}
</style>